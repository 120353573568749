import request from '@/utils/request';
export function Structure(data) {
  //zzy公司组织架构
  return request({
    url: '/clientserver.php/Structure/index',
    method: 'POST',
    data: data
  });
}
export function addStructure(data) {
  //zzy新建公司组织架构列表
  return request({
    url: '/clientserver.php/Structure/addStructure',
    method: 'POST',
    data: data
  });
}
export function Read(data) {
  //zzy获取部门详情
  return request({
    url: '/clientserver.php/Structure/read',
    method: 'POST',
    data: data
  });
}
export function delStructure(data) {
  //zzy删除部门
  return request({
    url: '/clientserver.php/Structure/delStructure',
    method: 'POST',
    data: data
  });
}
export function getUserList(data) {
  //zzy获取企业通讯录
  return request({
    url: '/clientserver.php/Common/getUserList',
    method: 'POST',
    data: data
  });
}
export function getUserListShowTree(data) {
  //zzy获取企业通讯录
  return request({
    url: '/clientserver.php/Common/getUserListShowTree',
    method: 'POST',
    data: data
  });
}
export function getlower_structure_list(data) {
  //zml获取父级企业通讯录
  return request({
    url: '/clientserver.php/Common/get_lower_structure_list',
    method: 'POST',
    data: data
  });
}
export function exitStructure(data) {
  //zzy编辑部门
  return request({
    url: '/clientserver.php/Structure/exitStructure',
    method: 'POST',
    data: data
  });
}
export function StructureSetdirector(data) {
  //zzy设置主管
  return request({
    url: '/clientserver.php/Structure/setdirector',
    method: 'post',
    data: data
  });
}
export function AuthGroupSave(data) {
  //zzy新建角色
  return request({
    url: '/clientserver.php/AuthGroup/save',
    method: 'post',
    data: data
  });
}
export function AuthGroupUpdate(data) {
  //zzy编辑一个角色信息
  return request({
    url: '/clientserver.php/AuthGroup/update',
    method: 'post',
    data: data
  });
}
export function AuthGroupRead(data) {
  //zzy获取一个角色信息
  return request({
    url: '/clientserver.php/AuthGroup/read',
    method: 'post',
    data: data
  });
}
export function AuthGroupDelete(data) {
  //zzy删除一个角色信息
  return request({
    url: '/clientserver.php/AuthGroup/delete',
    method: 'post',
    data: data
  });
}
export function getGroupUsers(data) {
  //zzy获取角色人员列表信息
  return request({
    url: '/clientserver.php/AuthGroup/getGroupUsers',
    method: 'post',
    data: data
  });
}
export function StructureGetpeople(data) {
  //zzy人员列表
  return request({
    url: '/clientserver.php/Structure/getpeople',
    method: 'post',
    data: data
  });
}
export function GroupIndex(data) {
  //zzy所有岗位列表
  return request({
    url: '/clientserver.php/AuthGroup/index',
    method: 'post',
    data: data
  });
}
export function personshareList(data) {
  //zzy流量共享统计列表
  return request({
    url: '/clientserver.php/BulletinStatistics/directorshare',
    method: 'POST',
    data: data
  });
}
export function directorsourceList(data) {
  //zzy渠道来源统计列表
  return request({
    url: '/clientserver.php/BulletinStatistics/directorsource',
    method: 'POST',
    data: data
  });
}
export function getShareComparisonList(data) {
  //zzy共享客户数据列表
  return request({
    url: '/clientserver.php/ShareComparison/getShareComparisonList',
    method: 'POST',
    data: data
  });
}
export function getAllowDeleteStructureList(data) {
  //转移部门公海数据
  return request({
    url: '/clientserver.php/Structure/getAllowDeleteStructureList',
    method: 'POST',
    data: data
  });
}