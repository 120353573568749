/*
 * @Author: your name
 * @Date: 2021-06-01 13:58:16
 * @LastEditTime: 2021-06-02 18:03:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web_bcrm\src\api\homelook.js
 */
import request from '@/utils/request';
//用户看板 卡片
export function homelookcar(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/index',
    method: 'post',
    data: data
  });
}
//个人学员级别详情
export function Studentleve_list(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/personlevel',
    method: 'post',
    data: data
  });
}
//个人流转数据详情
export function circulation_list(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/personroam',
    method: 'post',
    data: data
  });
}
//主管看板卡片
export function executive_car(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/directorka',
    method: 'post',
    data: data
  });
}
//主管今日学员级别详情
export function executive_Studentleve(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/directorlevel',
    method: 'post',
    data: data
  });
}
//主管今日流转数据详情
export function executive_circulation(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/directorroam',
    method: 'post',
    data: data
  });
}

//主管通话数据监控详情
export function CallStatistics(data) {
  return request({
    url: '/clientserver.php/CallStatistics/my_list',
    method: 'post',
    data: data
  });
}

//用户通话
export function Callperson_list(data) {
  return request({
    url: '/clientserver.php/CallStatistics/person_list',
    method: 'post',
    data: data
  });
}
//流量共享统计
export function Personshare_list(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/personshare',
    method: 'post',
    data: data
  });
}
//渠道来源统计
export function personsource_list(data) {
  return request({
    url: '/clientserver.php/BulletinStatistics/personsource',
    method: 'post',
    data: data
  });
}
//天润通话统计
export function CallTianRun(data) {
  return request({
    url: '/clientserver.php/CallTianRun/my_list',
    method: 'post',
    data: data
  });
}
//呼入通话统计
export function incomingCall(data) {
  return request({
    url: '/clientserver.php/CallMaster/myListByComeIn',
    method: 'post',
    data: data
  });
}